import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { ReportPage } from '../pages/report/ReportPage'
import { SettingsWrapper } from '../modules/settings/SettingsPage'
import { BookingSettingsWrapper } from '../modules/online-booking-settings/BookingSettingsPage'
import BranchPage from '../modules/branch/BranchPage'
import BankAccountPage from '../modules/bank-account/BankAccountPage'

const PrivateRoutes = () => {
  const CalendarPage = lazy(() => import('../modules/full-calendar/CalendarPage'))
  const UserPage = lazy(() => import('../modules/user-management/UsersPage'))
  const CustomerPage = lazy(() => import('../modules/customer-management/CustomersPage'))
  const ServicePage = lazy(() => import('../modules/service-management/ServicesPage'))
  const ResourcePage = lazy(() => import('../modules/resources/ResourcesPage'))
  const DiscountPage = lazy(() => import('../modules/promote/discount/DiscountsPage'))
  const CouponPage = lazy(() => import('../modules/promote/coupon/CouponsPage'))
  const CouponCodePage = lazy(() => import('../modules/promote/coupon-code/CouponCodesPage'))
  const MembershipPage = lazy(() => import('../modules/promote/membership/MembershipsPage'))
  const MembershipTypePage = lazy(() => import('../modules/promote/membership-type/MembershipTypesPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/calendar/index' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
     
        <Route 
          path='calendar/*'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />
  
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='report'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />

        <Route
          path='service/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />

        <Route
          path='resource/*'
          element={
            <SuspensedView>
              <ResourcePage />
            </SuspensedView>
          }
        />

        <Route
          path='/promote/discount/*'
          element={
            <SuspensedView>
              <DiscountPage />
            </SuspensedView>
          }
        />

        <Route
          path='/promote/coupon/*'
          element={
            <SuspensedView>
              <CouponPage />
            </SuspensedView>
          }
        />

        <Route
          path='/promote/coupon-code/*'
          element={
            <SuspensedView>
              <CouponCodePage />
            </SuspensedView>
          }
        />

        <Route
          path='/promote/membership/*'
          element={
            <SuspensedView>
              <MembershipPage />
            </SuspensedView>
          }
        />

        <Route
          path='/promote/membership-type/*'
          element={
            <SuspensedView>
              <MembershipTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='branch/*'
          element={
            <SuspensedView>
              <BranchPage />
            </SuspensedView>
          }
        />

        <Route
          path='settings/master'
          element={
            <SuspensedView>
              <SettingsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='settings/online-booking'
          element={
            <SuspensedView>
              <BookingSettingsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='bank_account/*'
          element={
            <SuspensedView>
              <BankAccountPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
