import { FC } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../auth'
import { useCalendarData } from './core/CalendarDataProvider'

export const WelcomePage: FC = () => {
  const {onlineBookingSettings} = useCalendarData()
  const {settings} = useAuth()
  return (
    <div className='d-flex flex-column flex-root welcome'>
      <div className='d-flex flex-column flex-md-row flex-lg-row welcome-top'>
        <div className='d-flex flex-column w-lg-80 p-13' style={{justifyContent: 'center'}}>
          <div className='fs-2x fw-bolder text-gray-800'>{settings?.company_name}</div>
          <div className='fs-3 mt-2 mb-18'>
            <i className='fa fa-solid fa-location-dot text-primary fs-3'></i>
            {' '}{onlineBookingSettings.location}
          </div>
          <Link to='/booking/service' className='welcome-booking-btn btn btn-primary'>
            Захиалга өгөх
          </Link>
        </div>
        
        <div
          className='d-lg-flex flex-lg-row-fluid bgi-size-cover bgi-no-repeat welcome-img'
          style={{
            backgroundImage: `url("${onlineBookingSettings.image_url}")`,
          }}
        ></div>

        {/* ============= WAVE ============ */}
        <div className='wave'>
          <div className='box'></div>
          <div className='box'></div>
        </div>
      </div>


      <div className='d-md-flex welcome-about'>
        <div
          className='bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat welcome-company-img'
          style={{
            backgroundImage: `url("${'/media/mainImg.webp'}")`,
          }}
        >
        </div>
        
        <div className='d-flex flex-lg-row py-10 py-md-20 px-xl-20'>
          <div className='w-lg-600px w-100 px-15'>
            <div className='d-inline-block'>
              <div className='welcome-about-us fw-bolder text-gray-800'>Бидний тухай</div>
              <div className='separator mx-1 mb-4 mt-1 border-primary '></div>
            </div>
            <div className='welcome-about-us-content'>
              {onlineBookingSettings.about}
            </div>

            <div className='fs-4 mt-10 text-gray-800'>
              <i className='fa fa-solid fa-clock text-primary fs-4'></i>
              <span className='fw-bold'>{' Цагийн хуваарь'}</span>

              <div className='welcome-about-us-content'>
                  Ажлын өдрүүд: {settings?.start_time} - {settings?.end_time} <br />
                  Бямба, Ням: амарна
              </div>
            </div>
            
            <div className='fs-4 mt-5 text-gray-800'>

              <div className='welcome-about-us-content text-primary fs-5'>
                  <div className='pb-1'>
                    <i className='fa fa-solid fa-envelope fs-5 pe-2 text-primary'></i>
                    <a href='mailto:info@ubisol.mn' className='text-primary'>
                      {' info@ubisol.mn'}</a>
                  </div>
                  <div className='pb-1'>
                    <i className='fa fa-solid fa-phone fs-5 pe-2 text-primary'></i>
                    <span>{' 7500-4000'}</span>
                  </div>
                  <div className='pb-1'>
                    <i className='fa fa-solid fa-globe fs-5 pe-2 text-primary'></i>
                    <a href='http://xolbooc.com' rel='nofollow' className='text-primary'> {' Холбоос'}</a>
                  </div>
                  <div className='d-flex fb-1'>
                    <i className='fa fa-solid fa-share-alt fs-4 pe-2 text-primary pt-1'></i>
                    <div>
                        <Link
                          title='Go to facebook account. Open in new window'
                          to='https://www.facebook.com/profile.php?id=100087372374741'
                          target='_blank'
                          className='fb'
                        >
                          <KTSVG
                            path='/media/icons/duotune/social/soc004.svg'
                            className='svg-icon-2 svg-icon-primary'
                          />
                        </Link>
                        <Link
                          title='Go to instagram account. Open in new window'
                          to='https://www.facebook.com/profile.php?id=100087372374741'
                          target='_blank'
                          className='ig'
                        >
                          <KTSVG
                            path='/media/icons/duotune/social/soc005.svg'
                            className='svg-icon-2 svg-icon-primary'
                          />
                        </Link>
                    </div>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* =================================================== */}
     
    </div>
  )
}
