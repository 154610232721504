import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3, 
  StatsWidget1,
} from '../../../_metronic/partials/widgets'
import Moment from 'moment';
import { MonthIncome, UserAppointment, UserIncome } from './core/_models';
import { useQuery } from 'react-query';
import { getDashboardDatas } from './core/_requests';
import { dashboardType } from './core/consts';
import { objectHasAttr } from '../../../_metronic/helpers';

const DashboardPage = () => {
  const [totalIncomeData, setTotalIncomeData] = useState<Array<MonthIncome>>([])
  const [appointmentStatusData, setAppointmentStatusData] = useState<any>([])
  const [userIncome, setUserIncome] = useState<Array<UserIncome>>([])
  const [userAppointment, setUserAppointment] = useState<Array<UserAppointment>>([])

  const [chartOneInterval, setChartOneInterval] = useState([Moment().format('YYYY-01-01'), Moment().format('YYYY-12-31')])
  const [chartTwoInterval, setChartTwoInterval] = useState([Moment().format('YYYY-01-01'), Moment().format('YYYY-12-31')])
  const [chartThreeInterval, setChartThreeInterval] = useState([Moment().format('YYYY-01-01'), Moment().format('YYYY-12-31')])
  const [chartFourInterval, setChartFourInterval] = useState([Moment().format('YYYY-01-01'), Moment().format('YYYY-12-31')])

  const {
    refetch,
    data: response,
  } = useQuery(
      `dashboard-data`,
      () => {
        const values = {
          chartOneInterval,
          chartTwoInterval,
          chartThreeInterval,
          chartFourInterval,
        }
        
        return getDashboardDatas(values)
      },
      {
        cacheTime: 0,
        onError: (err) => {
            console.error('getDashboardDatas error', err)
        }
      }
  )

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    if(response && response.data) {
      setTotalIncomeData(response.data.totalIncome)
      setAppointmentStatusData(response.data.appointmentStatusData)
      setUserIncome(response.data.userIncome)
      setUserAppointment(response.data.userAppointment)
    }
  }, [response])

  useEffect(() => {
    refetch()
  }, [chartOneInterval, chartTwoInterval, chartThreeInterval, chartFourInterval])

  const dateInterval = (interval: string, chartType: string) => {
    const yearInterval = [Moment().startOf('year').format('YYYY-MM-DD'), Moment().endOf('year').format('YYYY-MM-DD')]
    const lastYearInterval = [Moment().startOf('year').subtract('1', 'year').format('YYYY-MM-DD'), Moment().endOf('year').subtract('1', 'year').format('YYYY-MM-DD')]
    const thisMonthInterval = [Moment().startOf('month').format('YYYY-MM-DD'), Moment().endOf('month').format('YYYY-MM-DD')]
    const lastMonthInterval = [Moment().startOf('month').subtract('1', 'month').format('YYYY-MM-DD'), Moment().endOf('month').subtract('1', 'month').format('YYYY-MM-DD')]

    const dates = interval === 'thisYear' ? yearInterval : interval === 'lastYear' ? lastYearInterval : interval === 'thisMonth' ? thisMonthInterval : lastMonthInterval

    chartType === dashboardType.chartOne && setChartOneInterval(dates)
    chartType === dashboardType.chartTwo && setChartTwoInterval(dates)
    chartType === dashboardType.chartThree && setChartThreeInterval(dates)
    chartType === dashboardType.chartFour && setChartFourInterval(dates)
  }

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-6'>
          {totalIncomeData.length > 0 && 
            <ChartsWidget3 className='card-xl-stretch mb-xl-8'
              dataProp={totalIncomeData}
              dateInterval={dateInterval}
            />
          }
        </div>

        <div className='col-xxl-6'>
          {objectHasAttr(appointmentStatusData) && 
            <StatsWidget1 className='card-xl-stretch mb-xl-8'
              dataProp={appointmentStatusData}
              dateInterval={dateInterval}
            />
          }
        </div>

        <div className='col-xxl-6'>
          {userIncome.length > 0 && 
            <ChartsWidget1 className='card-xl-stretch mb-xl-8'
              dataProp={userIncome}
              dateInterval={dateInterval}
            />
          }
        </div>
        <div className='col-xxl-6'>
          {userAppointment.length > 0 && 
            <ChartsWidget2 className='card-xl-stretch mb-xl-8'
              dataProp={userAppointment}
              dateInterval={dateInterval}
            />
          }
        </div>
      </div>

    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>
        Хянах самбар
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
