import { Card4 } from "../../../_metronic/partials/content/cards/Card4"
import Datetime from 'react-datetime';
import { CRUD_RESPONSES, KTSVG, toExcelUrl } from "../../../_metronic/helpers";
import Moment from 'moment';
import { useState } from "react";
import { NotifySuccess } from "../../../_metronic/helpers/notify/NotifySuccess";
import { WarningAlert } from "../../../_metronic/helpers/alerts/Warning";
import { ErrorAlert } from "../../../_metronic/helpers/alerts/Error";
import { getGeneralReport, incomeReportByUsers, incomeReportByDays, attendanceReportByUsers, attendanceReportByServices, attendanceReportByRushHours } from "./core/_requests";
import { ReportLoading } from "./loading/ReportLoading";
import { saveAs } from "file-saver";

const datePickerProps = {
    style: {
        width: '165px',
        padding: '8px 11px'
    },
    className: 'form-control bg-body ps-12'
}

export const ReportPage = () => {          
    // var FileSaver = require('file-saver');
    const [startDate, setStartDate] = useState(Moment().format('YYYY/MM/01'))
    const [endDate, setEndDate] = useState(Moment().format('YYYY/MM/DD'))
    const [loading, setLoading] = useState(false)

    const handleDownload = async (apiRequest: any) => {
        try {
            setLoading(true)
            const response = await apiRequest({startDate, endDate})
            const fileName = response.data.file_name

            const url = toExcelUrl(fileName)
            saveAs(url, fileName);
        } catch (ex: any) {
            setLoading(false)
            console.error(ex)
            ex.response?.status === 403 ?
                WarningAlert(CRUD_RESPONSES.failed_authorization)
            :
                ErrorAlert(CRUD_RESPONSES.error)
        } finally {
            NotifySuccess('Амжилттай татагдлаа.')
            setLoading(false)
        } 
    }

    const downloadGeneralReport = () => {
        handleDownload(getGeneralReport)
    }

    const downloadIncomeReportByDays = () => {
        handleDownload(incomeReportByDays)
    }

    const downloadIncomeReportByUsers = () => {
        handleDownload(incomeReportByUsers)
    }

    const downloadAppointmentReportByUsers = () => {
        handleDownload(attendanceReportByUsers)
    }

    const downloadAppointmentReportByServices = () => {
        handleDownload(attendanceReportByServices)
    }

    const downloadAppointmentReportByRushHours = () => {
        handleDownload(attendanceReportByRushHours)
    }

    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_content" className="app-content flex-column-fluid">
				    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="d-flex flex-wrap flex-stack my-5">
                            <h3 className="fw-bold my-2">Тайлан татах
                            {/* <span className="fs-6 text-gray-400 fw-semibold ms-1">+590</span> */}
                            </h3>
                            
                            <div className="d-flex my-2">
                                <div className='d-flex align-items-center'>
                                    <div className="d-flex align-items-center position-relative me-4">
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen014.svg'
                                            className='svg-icon-1 svg-icon-primary position-absolute translate-middle-y top-50 ps-3 z-index-1'
                                        />
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat='YYYY/MM/DD'
                                            initialValue={startDate}
                                            inputProps={datePickerProps}
                                            onChange={(val) => {setStartDate(Moment(val).format('YYYY/MM/DD'))}}
                                        />
                                    </div>

                                    <div className="d-flex align-items-center position-relative">
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen014.svg'
                                            className='svg-icon-1 svg-icon-primary position-absolute translate-middle-y top-50 ps-3 z-index-1'
                                        />
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat='YYYY/MM/DD'
                                            initialValue={endDate}
                                            inputProps={datePickerProps}
                                            onChange={(val) => {setEndDate(Moment(val).format('YYYY/MM/DD'))}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-info.svg' 
                                    title='Нэгдсэн тайлан' 
                                    description='Дэлгэрэнгүй' 
                                    handleDownload={downloadGeneralReport}
                                />
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-red.svg' 
                                    title='Орлогын тайлан' 
                                    description='Ажилчдаар' 
                                    handleDownload={downloadIncomeReportByUsers}/>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-dark.svg' 
                                    title='Орлогын тайлан' 
                                    description='Өдрөөр' 
                                    handleDownload={downloadIncomeReportByDays}/>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-pink.svg' 
                                    title='Цаг захиалга' 
                                    description='Ажилчдаар' 
                                    handleDownload={downloadAppointmentReportByUsers}/>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-yellow.svg'
                                    title='Цаг захиалга' 
                                    description='Үйлчилгээгээр' 
                                    handleDownload={downloadAppointmentReportByServices}/>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Card4 icon='/media/svg/files/xls-green.svg'
                                    title='Цаг захиалга' 
                                    description='Цагийн ачааллаар' 
                                    handleDownload={downloadAppointmentReportByRushHours}/>
                            </div>
                        </div>

                    </div>

                    {loading && <ReportLoading />}
                </div>
            </div>
        </div>
    )
}