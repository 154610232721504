import { Link, useNavigate } from "react-router-dom"
import Moment from 'moment';
import clsx from "clsx";
import { useCalendarItem } from "../../core/CalendarItemProvider";
import { TimeItem } from "./TimeItem";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAvailableHours } from "../../core/_requests";
import { BOOKING_STEPS, QUERIES } from "../../../../../_metronic/helpers";

export const DateIndex = () => {
    const navigate = useNavigate()
    const {itemDatas, setItemDatas, setActiveTab} = useCalendarItem()
    const [hours, setHours] = useState<Array<string>>()
    const [eventDate, setEventDate] = useState(itemDatas.event_date || Moment().format('YYYY/MM/DD'))
    const [firstRender, setFirstRender] = useState(true)
    const enabledQuery: boolean = firstRender
    const weekends = [
        {'value': 1, 'name': 'Да'},
        {'value': 2, 'name': 'Мя'}, 
        {'value': 3, 'name': 'Лх'}, 
        {'value': 4, 'name': 'Пү'}, 
        {'value': 5, 'name': 'Ба'}, 
        {'value': 6, 'name': 'Бя'}, 
        {'value': 0, 'name': 'Ня'}
    ]
    const dateLength = [0,1,2,3,4,5,6,7,8,9]

    const handleDate = (date: string) => {
        setItemDatas({...itemDatas, event_date: date})
        setEventDate(date)
    }

    const handleTime = (time: string) => {
        setItemDatas({...itemDatas, start_time: time})

        if(itemDatas.event_date && time) {
            setActiveTab(BOOKING_STEPS.THREE)
            navigate('/booking/customer')
        }
    }

    useEffect(() => {
        setFirstRender(false)
        setItemDatas({...itemDatas, event_date: eventDate})
    }, [])

    const {
        refetch,
        data: response,
    } = useQuery(`${QUERIES.ONLINE_BOOKING}-available_hours`,
        () => {
            return getAvailableHours(Moment(eventDate).format('YYYY-MM-DD'))
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
        }
    )

    useEffect(() => {
        if(response) {
            setHours(response.available_hours)
        }
    }, [response])

    useEffect(() => {
        refetch()
    }, [eventDate])

    return (
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush">
                <div className="card-header mt-3">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1">Цаг захиалах өдрөө сонгоно уу</h3>
                        <div className="fs-6 text-gray-400">
                            {Moment().format('YYYY/MM/DD')} - {Moment().add(dateLength.length-1, 'days').format('YYYY/MM/DD')}
                        </div>
                    </div>
                </div>

                <div className="card-body p-9 pt-4">
                    <ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2 justify-content-lg-center justify-content-md-center">
                        {dateLength.map(dateIndex => {
                            const calendarDate = Moment().add(dateIndex, 'days')
                            const weekDay = calendarDate.day()
                            const weekName = weekends.filter(weekend => weekend.value === weekDay)[0].name
                            return (
                                <li className="nav-item me-1" key={dateIndex} 
                                    onClick={() => handleDate(calendarDate.format('YYYY/MM/DD'))}>
                                    <Link data-bs-toggle="tab" to="#"
                                        className={clsx('nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary',
                                        {'border border-primary': dateIndex === 0, 'active': eventDate === calendarDate.format('YYYY/MM/DD')})}
                                    >
                                        <span className="opacity-50 fs-7 fw-semibold">{weekName}</span>
                                        <span className="fs-6 fw-bold">{calendarDate.format('DD')}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="h-400px h-lg-500px h-xl-600px hover-scroll-y py-2 justify-content-center px-md-20 px-xl-20
                        ms-sm-0 ms-md-5 ms-lg-10 ms-xl-15">
                        {hours && hours.map((hour, index) => {
                            return (
                                <TimeItem key={index} hour={hour} handleTime={handleTime}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}