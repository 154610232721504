import { FC } from "react"
import { Outlet } from "react-router-dom"
import { WithChildren } from "../../../_metronic/helpers"
import BookingHeader from "./BookingHeader"
import { BookingAside } from "./components/BookingAside"

const BookingBody: FC<WithChildren> = ({children}) => {
    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid'>
                <BookingHeader />

                <div className="scroll-y flex-column-fluid px-10 py-10 w-100" 
                    data-kt-scroll="true" 
                    data-kt-scroll-activate="true" 
                    data-kt-scroll-height="500px" 
                    data-kt-scroll-offset="5px" 
                    data-kt-scroll-save-state="true"
                    style={{backgroundColor: '#D5D9E2'}}>
                    <div style={{ 
                        fontFamily: 'Arial,Helvetica,sans-serif', 
                        lineHeight: '1.5', 
                        minHeight: '100%', 
                        fontWeight: 'normal', 
                        fontSize: '15px', 
                        width: '100%'
                    }}>
                        <div className="form d-flex flex-column flex-lg-row">
                            <BookingAside />
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {BookingBody}