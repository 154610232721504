import { Dispatch, SetStateAction } from "react"
import { BOOKING_STEPS, ID } from "../../../../_metronic/helpers"
import { OnlineBookingSettings } from "../../online-booking-settings/core/_models"

export type Event = {
    id?: ID
    appointment_id?: ID
    start?: string
    end?: string
    event_date?: string
    start_datetime?: string
    title?: string
    desc?: string
    customer_id?: ID 
    resourceId?: ID 
    status?: string 
    item_values?: Array<Item>
    ref?: any
    total_payment?: string
    total_paid?: string
}

export type OnlineCustomer = {
    lastname?: string
    firstname?: string
    email?: string
    phone?: string
    phone2?: string
    desc?: string
}

export type Service = {
    id?: ID
    value?: ID
    label?: string
    name?: string
    duration?: string
    price?: string
    allow_resources?: boolean
    resources?: Array<ServiceResource>
}

export type ServiceResource = {
    id?: ID
    resource_id?: ID
    service_id?: ID
}

export type ServiceCategory = {
    id: ID
    name: string
    services: Array<Service>
}

export type Resource = {
    value?: ID
    label?: string
}

export type User = {
    value?: ID
    label?: string
    id?: ID
    title?: string
}

export type MasterData = {
    serviceCategories: Array<ServiceCategory>
    users: Array<User>
    bookingSettings: OnlineBookingSettings
}

export type Item = {
    id?: ID
    event_date?: string
    start_time?: string
    service_ids: Array<ID>
    user_id?: ID
    customer?: OnlineCustomer
}

export type AvailableHours = {
    available_hours: Array<string>
    hours_by_users: Array<AvailableHoursByUsers>
}

export type AvailableHoursByUsers = {
    user_id: ID
    available_hours: Array<string>
}

export type QueryState = {
    search: string
}

export type CalendarDataContextProps = {
    services: Array<Service>
    serviceCategories: Array<ServiceCategory>
    users: Array<User>
    onlineBookingSettings: OnlineBookingSettings
    refetch: () => void
}

export type CalendarItemContextProps = {
    itemDatas: Item
    setItemDatas: Dispatch<SetStateAction<Item>>
    activeTab: ID
    setActiveTab: Dispatch<SetStateAction<ID>>
    readyNextStep: () => boolean
}

export const initialCalendarData: CalendarDataContextProps = {
    services: [],
    serviceCategories: [],
    users: [],
    onlineBookingSettings: {},
    refetch: () => {}
}

export const initialCalendarItem: CalendarItemContextProps = {
    itemDatas: {
        service_ids: []
    },
    setItemDatas: () => {},
    activeTab: BOOKING_STEPS.ONE,
    setActiveTab: () => {},
    readyNextStep: () => false
}

export type Settings = {
    id?: ID
    company_name?: string
    phone?: string
    slot_duration?: string
    start_time?: string
    end_time?: string
}