/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import { ROLES } from '../../../helpers'

export function AsideMenuMain() {
  const intl = useIntl()
  const {settings, currentUser, paymentMethods} = useAuth()
  const userRole = currentUser?.role as string
  const activeCoupon = paymentMethods && paymentMethods.filter((paymentMethod) => {return (paymentMethod.slug === 'coupon' && paymentMethod.active)})[0]?.active
  const activeMembership = paymentMethods && paymentMethods.filter((paymentMethod) => {return (paymentMethod.slug === 'membership' && paymentMethod.active)})[0]?.active
  const activeDiscountCard = paymentMethods && paymentMethods.filter((paymentMethod) => {return (paymentMethod.slug === 'discount_card' && paymentMethod.active)})[0]?.active

  return (
    <>
      <AsideMenuItem
        to='calendar/index'
        title='Календар'
        fontIcon='bi-calendar3'
        bsTitle='Календар'
        className='py-3'
      />
      {userRole === ROLES.ADMIN && <>
        <AsideMenuItemWithSubMain to='/reports' title='Тайлан' fontIcon='bi-graph-up-arrow'>
          <AsideMenuItem to='/dashboard' title='График, чарт' hasBullet={true} bsTitle='График, чарт'/>
          <AsideMenuItem to='/report' title='Эксел тайлан' hasBullet={true} bsTitle='Эксел тайлан'/>
        </AsideMenuItemWithSubMain>
        </>
      }

      <AsideMenuItem
        to='/user/list'
        title='Ажилтан'
        fontIcon='bi-person-check'
        bsTitle='Ажилчид'
        className='py-3'
      />

      <AsideMenuItem
        to='/customer/list'
        title='Үйлчлүүлэгч'
        fontIcon='bi-people'
        bsTitle='Үйлчлүүлэгчид'
        className='py-3'
      />

      <AsideMenuItemWithSubMain to='/services' title='Үйлчилгээ, Нөөц' fontIcon='bi-hdd-stack'>
        <AsideMenuItem to='/service/list' title='Үйлчилгээ' hasBullet={true} bsTitle='Үйлчилгээ'/>
        <AsideMenuItem to='/resource/list' title='Нөөц' hasBullet={true} bsTitle='Нөөц'/>
      </AsideMenuItemWithSubMain>

      {[ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.RECEPTION].includes(userRole) && <>
        <AsideMenuItemWithSubMain to='/discounts' title='Хөнгөлөлт' fontIcon='bi-star'>
          <AsideMenuItem to='/promote/discount/list' title='Хөнгөлөлт' hasBullet={true} bsTitle='Хөнгөлөлт'/>
          {activeCoupon && <>
            <AsideMenuItem to='/promote/coupon/list' title='Купон төрлүүд' hasBullet={true} bsTitle='Купон төрлүүд'/>
            <AsideMenuItem to='/promote/coupon-code/list' title='Купон' hasBullet={true} bsTitle='Купон'/>
          </>}
          {(activeMembership || activeDiscountCard) && <>
            <AsideMenuItem to='/promote/membership-type/list' title='Гишүүнчлэлийн төрөл' hasBullet={true} bsTitle='Гишүүнчлэлийн төрөл'/>
            {activeMembership && <AsideMenuItem to='/promote/membership/list' title='Гишүүнчлэл' hasBullet={true} bsTitle='Гишүүнчлэл'/>}
          </>}
        </AsideMenuItemWithSubMain>
      </>
      }

      {userRole === ROLES.ADMIN && <>
        {settings?.has_branch && 
          <AsideMenuItem
            to='/branch/list'
            title='Салбар'
            fontIcon='bi-building'
            bsTitle='Салбарууд'
            className='py-3'
          />
        }

        <AsideMenuItemWithSubMain to='/settings' title='Тохиргоо' fontIcon='bi-gear'>
          <AsideMenuItem to='/settings/master' title='Мастер тохиргоо' hasBullet={true} bsTitle='Мастер тохиргоо'/>
          <AsideMenuItem to='/bank_account/list' title='Дансны мэдээлэл' hasBullet={true} bsTitle='Дансны мэдээлэл'/>
          <AsideMenuItem to='/settings/online-booking' title='Онлайн захиалга' hasBullet={true} bsTitle='Онлайн захиалгын тохиргоо'/>
        </AsideMenuItemWithSubMain>
      </>
    }

    </>
  )
}
