import * as Yup from 'yup'
import { useFormik } from 'formik';
import { ConvertFileToBase64, CRUD_RESPONSES, DropzoneComponent } from '../../../_metronic/helpers';
import { OnlineBookingSettings } from './core/_models';
import { FC, useState } from 'react';
import { updateBookingSettings } from './core/_requests';
import { NotifySuccess } from '../../../_metronic/helpers/notify/NotifySuccess';
import { WarningAlert } from '../../../_metronic/helpers/alerts/Warning';
import { ErrorAlert } from '../../../_metronic/helpers/alerts/Error';
import { useAuth } from '../auth';

const settingsSchema = Yup.object().shape({
    location: Yup.string().required('Байгууллагын хаяг оруулна уу'),
    about: Yup.string().required('Байгууллагын тухай мэдээлэл оруулна уу'),
})

type Props = {
    settings: OnlineBookingSettings
}

export const BookingSettingsDetail: FC<Props> = ({settings}) => {
    const {refetch} = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [data] = useState<OnlineBookingSettings>({
        ...settings,
        image: settings.image || [],
        choose_user: settings.choose_user || false,
        about: settings.about || '',
        important_info: settings.important_info || '',
        location: settings.location || '',
        file: settings.file || {},
    })

    const formik = useFormik({
        initialValues: data,
        validationSchema: settingsSchema,
        onSubmit: async(values, {setSubmitting}) => {
            setIsLoading(true)
            setSubmitting(false)
            try {
                const response = await updateBookingSettings(values)
                const status = response.payload?.status
                status && status === 200 && NotifySuccess(CRUD_RESPONSES.success)
            } catch (ex: any) {
                console.error(ex)
                ex.response?.status === 403 ?
                    WarningAlert(CRUD_RESPONSES.failed_authorization)
                :
                    ErrorAlert(CRUD_RESPONSES.error)
            } finally {
                setIsLoading(false)
                setSubmitting(true)
                NotifySuccess('Амжилттай хадгалагдлаа')
                refetch()
            }
        },
    })

    const setFile = (field: string, value: any) => {
        ConvertFileToBase64(value[0]).then(response => {
          formik.setFieldValue(field, response)
        })
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title fs-3 fw-bolder">Ерөнхий мэдээлэл</div>
            </div>
            <form className="form" onSubmit={formik.handleSubmit} noValidate>
                <div className="card-body p-9">

                    <div className="row mb-5">
                        <div className="col-lg-3">
                            <div className="fs-6 fw-bold mt-2 mb-3">Нүүр зураг</div>
                        </div>
                        
                        <div className="col-lg-8">
                            <DropzoneComponent setAcceptedImg={setFile} data={formik.values.image}/>
                            <input {...formik.getFieldProps('file')} hidden/>
                            <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-lg-3">
                            <div className="fs-6 fw-bold mt-2 mb-3">Ажилтан сонгож захиалга өгөх</div>
                        </div>

                        <div className='col-lg-8 fv-row'>
                            <div className='form-check form-check-solid form-switch fv-row'>
                                <input
                                    className='form-check-input w-45px h-30px'
                                    type='checkbox'
                                    {...formik.getFieldProps('choose_user')}
                                    checked={formik.values.choose_user}
                                />
                                <label className='form-check-label'></label>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-lg-3">
                            <div className="fs-6 fw-bold mt-2 mb-3 required">Хаяг</div>
                        </div>
                        <div className="col-lg-8 fv-row">
                            <input type="text" 
                                className="form-control" 
                                {...formik.getFieldProps('location')} 
                            />  
                            {formik.touched.location && formik.errors.location && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.location}</div>
                            </div>
                            )}                       
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-lg-3">
                            <div className="fs-6 fw-bold mt-2 mb-3 required">Бидний тухай</div>
                        </div>
                        <div className="col-lg-8 fv-row">
                            <textarea
                                className="form-control" 
                                placeholder="Нүүр хуудсанд гарах дэлгэрэнгүй мэдээлэл" 
                                {...formik.getFieldProps('about')} 
                            />
                            {formik.touched.about && formik.errors.about && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.about}</div>
                            </div>
                            )}                         
                        </div>
                    </div>

                    <div className="row mb-8">
                        <div className="col-lg-3">
                            <div className="fs-6 fw-bold mt-2 mb-3 required">Анхааруулга текст</div>
                        </div>
                        <div className="col-lg-8 fv-row">
                            <textarea
                                className="form-control" 
                                placeholder="Анхааруулах тэмдэглэл" 
                                {...formik.getFieldProps('important_info')} 
                            />                      
                        </div>
                    </div>
                        
                </div>
                
               
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button type="reset" className="btn btn-light btn-active-light-primary me-2">Цуцлах</button>
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>Хадгалах</span>
                        {(formik.isSubmitting || isLoading) && (
                        <span className='indicator-progress'>
                            Түр хүлээнэ үү...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}