import { createContext, FC, useContext, useState } from "react"
import { bookingSteps, BOOKING_STEPS, ID, isNotEmpty, objectHasAttr, WithChildren } from "../../../../_metronic/helpers"
import { NotifyError } from "../../../../_metronic/helpers/notify/NotifyError"
import { CalendarItemContextProps, initialCalendarItem, Item } from "./_models"

const CalendarItemContext = createContext<CalendarItemContextProps>(initialCalendarItem)

const CalendarItemProvider: FC<WithChildren> = ({children}) => {
    const [itemDatas, setItemDatas] = useState<Item>(initialCalendarItem.itemDatas)
    const [activeTab, setActiveTab] = useState<ID>(initialCalendarItem.activeTab)

    const readyNextStep = () => {
        let hasValue = false
        if(activeTab === BOOKING_STEPS.ONE)
            hasValue = objectHasAttr(itemDatas.service_ids)
        else if(activeTab === BOOKING_STEPS.TWO)
            hasValue = isNotEmpty(itemDatas.event_date) && isNotEmpty(itemDatas.start_time)
        else if(activeTab === BOOKING_STEPS.THREE)
            if(objectHasAttr(itemDatas.customer) && itemDatas.customer?.lastname && itemDatas.customer.firstname && itemDatas.customer.phone) {
                hasValue = true
            }
        else if(activeTab === BOOKING_STEPS.FOUR)
            hasValue = true

        if(!hasValue) {
            const message = bookingSteps.filter(errorMsg => errorMsg.value === activeTab)[0].errorMessage
            NotifyError(message)
        }
        
        return hasValue
    }

    return (
        <CalendarItemContext.Provider 
            value={{
                itemDatas, 
                setItemDatas,
                activeTab,
                setActiveTab,
                readyNextStep
            }}
        >
            {children}
        </CalendarItemContext.Provider>
    )
}

const useCalendarItem = () => useContext(CalendarItemContext)

export {CalendarItemProvider, useCalendarItem}