import axios, { AxiosResponse } from "axios"
import { Response } from "../../../../_metronic/helpers"
import { AvailableHours, Item, MasterData } from "./_models"

const APP_URL = process.env.REACT_APP_API_URL
const BOOKING_URL = `${APP_URL}/online_booking`
const MASTER_DATA_URL = `${BOOKING_URL}/master_data`
const AVAILABLE_HOURS_URL = `${BOOKING_URL}/available_hours`

const getMasterDatas = (): Promise<MasterData> => {
    return axios
        .get(MASTER_DATA_URL)
        .then((response: AxiosResponse<MasterData>) => response.data)
}

const getAvailableHours = (date:string): Promise<AvailableHours> => {
    return axios
        .get(`${AVAILABLE_HOURS_URL}/${date}`, )
        .then((response: AxiosResponse<AvailableHours>) => response.data)
}

const bookingAppointment = (itemData: Item): Promise<Response<Event>> => {
    return axios
        .post(`${BOOKING_URL}/appointment`, itemData)
        .then((response: AxiosResponse<Response<Event>>) => response.data)
}

export {
    getMasterDatas,
    getAvailableHours,
    bookingAppointment
}