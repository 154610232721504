import clsx from "clsx"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { BOOKING_STEPS, KTSVG } from "../../../_metronic/helpers"
import { useCalendarItem } from "./core/CalendarItemProvider"
import { initialCalendarItem } from "./core/_models"

const BookingHeader = () => {
    const navigate = useNavigate()
    const {activeTab, setActiveTab, readyNextStep, setItemDatas} = useCalendarItem()

    useEffect(() => {
        navigate('/booking/service')
    }, [])

    const cancel = () => { 
        navigate(-1)
    }
    
    return (
        <div className="bg-body d-flex flex-column-auto justify-content-center align-items-start gap-2 gap-lg-4 py-4 px-10 overflow-auto w-100">
            <Link to="#" onClick={() => cancel()} className="flex-grow-1 mt-2">
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon svg-icon-gray-400 ms-4 mb-1 svg-icon-2x'
                />
            </Link>
            <div className={clsx('btn btn-icon flex-column btn-text-gray-500 btn-icon-gray-300 btn-active-text-gray-700 btn-active-icon-gray-500 rounded-3 h-60px w-60px h-lg-90px w-lg-90px fw-semibold', 
                {'active bg-light border border-2': activeTab === BOOKING_STEPS.ONE})}
                onClick={() => {
                    setActiveTab(BOOKING_STEPS.ONE)
                    navigate('/booking/service')
                }}
            >
                <i className="bi bi-hdd-stack fs-1 mb-2 text-primary"></i>
                <span className="fs-8 fw-bold">Үйлчилгээ
                <br />сонгох</span>
            </div>
            <div className={clsx('btn btn-icon flex-column btn-text-gray-500 btn-icon-gray-300 btn-active-text-gray-700 btn-active-icon-gray-500 rounded-3 h-60px w-60px h-lg-90px w-lg-90px fw-semibold', 
                {'active bg-light border border-2': activeTab === BOOKING_STEPS.TWO})}
                onClick={() => {
                    const hasValue = readyNextStep()
                    if(hasValue) {
                        setActiveTab(BOOKING_STEPS.TWO)
                        navigate('/booking/date')
                    }
                }}
            >
                <i className="bi bi-clock fs-1 mb-2 text-primary"></i>
                <span className="fs-8 fw-bold">Цаг
                <br />сонгох</span>
            </div>
            <div className={clsx('btn btn-icon flex-column btn-text-gray-500 btn-icon-gray-300 btn-active-text-gray-700 btn-active-icon-gray-500 rounded-3 h-60px w-60px h-lg-90px w-lg-90px fw-semibold', 
                {'active bg-light border border-2': activeTab === BOOKING_STEPS.THREE})}
                onClick={() => {
                    const hasValue = readyNextStep()
                    if(hasValue) {
                        setActiveTab(BOOKING_STEPS.THREE)
                        navigate('/booking/customer')
                    }
                }}
            >
                <i className="bi bi-person-check fs-1 mb-2 text-primary"></i>
                <span className="fs-8 fw-bold">Үйлчлүүлэгч
                <br />бүртгэх</span>
            </div>
            <div className={clsx('btn btn-icon flex-column btn-text-gray-500 btn-icon-gray-300 btn-active-text-gray-700 btn-active-icon-gray-500 rounded-3 h-60px w-60px h-lg-90px w-lg-90px fw-semibold', 
                {'active bg-light border border-2': activeTab === BOOKING_STEPS.FOUR})}
                onClick={() => {
                    const hasValue = readyNextStep()
                    if(hasValue) {
                        setActiveTab(BOOKING_STEPS.FOUR)
                        navigate('/booking/note')
                    }
                }}
            >
                <i className="bi bi-exclamation-circle fs-1 mb-2 text-primary"></i>
                <span className="fs-8 fw-bold">Анхаарах
                <br />зүйлс</span>
            </div>
            <Link to="/booking/index" 
                onClick={() => {
                    setItemDatas(initialCalendarItem.itemDatas)
                    setActiveTab(BOOKING_STEPS.ONE)
                }}
                className="flex-grow-1 text-end mt-2">
                <KTSVG
                    path='/media/icons/duotune/general/gen034.svg'
                    className='svg-icon svg-icon-gray-400 svg-icon-2x'
                />
            </Link>
        </div>

    )
}

export default BookingHeader