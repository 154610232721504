import { useNavigate } from "react-router-dom"
import { BOOKING_STEPS } from "../../../../../_metronic/helpers"
import { useCalendarData } from "../../core/CalendarDataProvider"
import { useCalendarItem } from "../../core/CalendarItemProvider"
import { ServiceItem } from "./ServiceItem"

export const ServiceIndex = () => {
    const navigate = useNavigate()
    const {serviceCategories} = useCalendarData()
    const {readyNextStep, setActiveTab} = useCalendarItem()

    return (
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div>
                {serviceCategories.map(serviceCategory => {
                    return (
                        <ServiceItem serviceCategory={serviceCategory} key={serviceCategory.id}/>
                    )
                })}

                <div className="d-flex justify-content-end pt-5 mt-5"> 
                    <div className="d-flex" onClick={() => {
                        const hasValue = readyNextStep()
                        if(hasValue) {
                            setActiveTab(BOOKING_STEPS.TWO)
                            navigate('/booking/date')
                        } 
                    }}>
                        <button className="btn btn-sm btn-primary">
                            <span className='indicator-label'>Үргэлжлүүлэх</span>                            
                        </button>
                    </div> 
                </div> 
                
            </div>
        </div>
    )
}
