import { useNavigate } from "react-router-dom"
import { useCalendarData } from "../core/CalendarDataProvider";
import { bookingAppointment } from "../core/_requests";
import { useCalendarItem } from "../core/CalendarItemProvider";
import { NotifySuccess } from "../../../../_metronic/helpers/notify/NotifySuccess";
import { NotifyError } from "../../../../_metronic/helpers/notify/NotifyError";
import { BOOKING_STEPS, CRUD_RESPONSES } from "../../../../_metronic/helpers";
import { WarningAlert } from "../../../../_metronic/helpers/alerts/Warning";
import { ErrorAlert } from "../../../../_metronic/helpers/alerts/Error";
import { initialCalendarItem } from "../core/_models";

export const NotePage = () => {
    const navigate = useNavigate()

    const {onlineBookingSettings} = useCalendarData()
    const {itemDatas, setItemDatas, setActiveTab} = useCalendarItem()

    const handleSubmit = async() => {
        try {
            const response = await bookingAppointment(itemDatas)
            const status = response.payload?.status
    
            status && status === 200 && NotifySuccess(CRUD_RESPONSES.success) 
            status && status === 201 && NotifyError(CRUD_RESPONSES.role_limit) 
        } catch (ex: any) {
            console.error(ex)
            ex.response?.status === 403 ? 
            WarningAlert(CRUD_RESPONSES.failed_authorization)
            : 
            ErrorAlert(CRUD_RESPONSES.error)
        } finally {
            setItemDatas(initialCalendarItem.itemDatas)
            setActiveTab(BOOKING_STEPS.ONE)
            navigate('/booking/index')
        }
    }

    return (
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <div className="card card-flush">
                <div className="card-header">
                    <div className="card-title flex-column">
                        <h3 className="fw-bold mb-1">Анхааруулга</h3>
                    </div>
                </div>

                <div className="card-body p-9 pt-4">
                    <div className="pt-3 notice d-flex bg-light-primary rounded border-primary border p-6">
                        <div className="d-flex flex-stack flex-grow-1 fs-6 text-gray-700">
                            <div className="fw-bold">
                                {/* <div className="text-gray-800 fw-bolder mb-2">Нэмэлт мэдээлэл</div> */}
                                <div> {onlineBookingSettings.important_info} </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
            <div className="d-flex justify-content-end pt-1"> 
                <div onClick={() => handleSubmit()}>
                    <button className="btn btn-sm btn-primary">
                        <span className='indicator-label'>Хадгалах</span>                            
                    </button>
                </div> 
            </div> 
        </div>
    )
}