import clsx from "clsx"
import { FC } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useCalendarItem } from "../../core/CalendarItemProvider"

type Props = {
    hour: string
    handleTime: (hour: string) => void
}

export const TimeItem: FC<Props> = ({hour, handleTime}) => {
    const {itemDatas} = useCalendarItem()

    return (
        <div onClick={() => handleTime(hour)}
            className={clsx('btn btn-sm btn-active-light-primary py-2 px-2 px-md-0 border border-primary mx-3 my-1 w-80px w-md-150px text-gray-700',
            {'active': hour === itemDatas.start_time})}
        >
            <KTSVG path='/media/icons/duotune/general/gen013.svg'
                className='svg-icon-1 svg-icon-lg-1x svg-icon-primary '/>
            <span className="fs-lg-2 fs-3"> {hour} </span>
            
        </div>
    )
}