import { createContext, FC, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { objectHasAttr, QUERIES, WithChildren } from "../../../../_metronic/helpers"
import { CalendarDataContextProps, initialCalendarData, Service } from "./_models"
import { getMasterDatas } from "./_requests"

const CalendarDataContext = createContext<CalendarDataContextProps>(initialCalendarData)

const CalendarDataProvider: FC<WithChildren> = ({children}) => {
    const [services, setServices] = useState(initialCalendarData.services)
    const [serviceCategories, setServiceCategories] = useState(initialCalendarData.serviceCategories)
    const [users, setUsers] = useState(initialCalendarData.users)
    const [onlineBookingSettings, setOnlineBookingSettings] = useState(initialCalendarData.onlineBookingSettings)

    const {
        refetch,
        data: response,
    } = useQuery(`${QUERIES.CALENDAR_MASTER_DATA}-datas`,
        () => {
            return getMasterDatas()
        },
        {cacheTime: 0}
    )

    useEffect(() => {
        if (response) {
            setServiceCategories(response.serviceCategories)
            setUsers(response.users)
            setOnlineBookingSettings(response.bookingSettings)
            let serviceArr: Array<Service> = []
            response.serviceCategories.map(serviceCategory => {
                if(objectHasAttr(serviceCategory.services)) {
                    serviceArr.push(...serviceCategory.services)
                }
            })
            setServices(serviceArr)
        }
    }, [response])

    return (
        <CalendarDataContext.Provider 
            value={{
                services,
                serviceCategories,
                users,
                onlineBookingSettings,
                refetch
            }}
        >
            {children}
        </CalendarDataContext.Provider>
    )
}

const useCalendarData = () => useContext(CalendarDataContext)

export {CalendarDataProvider, useCalendarData}